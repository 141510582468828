<template>
    <nuxt-link :to="facilityHomeLink(activeFacility.slug)">
        <!--If a facility logo is present use that, otherwise use organization logo-->
        <img
            :src="
                activeFacility.branding.logo ?? activeFacility.organization.logo
            "
            :alt="activeFacility.organization.longName"
            class="max-h-12"
        />
    </nuxt-link>
</template>

<script setup lang="ts">
import { useRouteLink } from '~/composables/routing'
import NuxtLink from '#app/components/nuxt-link'
import { useActiveFacility } from '~/composables/use-active-facility'

const activeFacility = useActiveFacility()
const { facilityHomeLink } = useRouteLink()
</script>
